.landing-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1;
}

.navbar a {
  text-decoration: none;
  color: #fff;
}

.navbar li a:hover {
  background-color: #fff;
  color: black;
  border-radius: 30px;
}

.Logo {
  height: auto;
  width: auto;
  max-height: 72px;
  max-width: 250px;
}

.home-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.home-section button {
  padding: 15px 30px;
  font-size: 20px;
  background-color: #494949;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
  display: flex;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.home-section button a {
  text-decoration: none;
  color: #000;
}

.home-section button:hover {
  background-color: #2d4373;
}

.about-us-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin: 90px 0;
}

.about-us-content h2 {
  font-size: 36px;
  margin-bottom: 20px;
  flex: auto;
}

.about-us-content p {
  font-size: 18px;
  max-width: 500px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.about-us-image img {
  max-width: 350px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.services-section {
  margin: 100px 0;
}

.services-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.services-section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.services-section ul li {
  font-size: 18px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}

.services-section ul li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
  width: 10px;
  height: 10px;
  background-color: #494949;
  border-radius: 50%;
}

.location-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin: 90px 0;
}

.location-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.location-section iframe {
  flex: auto;
  max-width: 400px;
}

.hours-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin: 90px 0;
}

.hours-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.hours-section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hours-section ul li {
  font-size: 18px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}

.hours-section ul li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
  width: 10px;
  height: 10px;
  background-color: #494949;
  border-radius: 50%;
}

.gallery-section {
  margin: 100px 0;
  padding: 20px;
  background-color: #494949;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.gallery-section h2 {
  font-size: 36px;
  margin-top: 5px;
  margin-bottom: 20px;
  color: #fff;
}

.gallery-images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.gallery-images img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.contact-us-section {
  margin: 100px 0;
  background-color: #494949;
  color: #fff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.contact-us-container {
  max-width: 600px;
  margin: 0 auto;
}

.contact-us-container h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.contact-form {
  margin-top: 50px;
  padding: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

label {
  font-size: 18px;
  margin-bottom: 10px;
}

input,
textarea {
  padding: 10px;
  font-size: 16px;
  resize: none;
  border: none;
  border-radius: 5px;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

button[type="submit"] {
  padding: 15px 30px;
  font-size: 20px;
  background-color: #2d4373;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

button[type="submit"]:hover {
  background-color: #fff;
  color: #000;
}

.social-media {
  text-align: center;
  margin: 50px auto;
}

.social-media h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
}

.social-icons a {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 10px;
  border-radius: 50%;
  color: #fff;
  background-color: #494949; /* Facebook blue */
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  transition: background-color 0.3s ease;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.social-icons a:hover {
  background-color: #2d4373; /* Darker Facebook blue */
}

.social-icons i {
  display: inline-block;
}

footer {
  background-color: #494949;
  color: #fff;
  padding: 10px;
  text-align: center;
}
